<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="card card-custom">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-1"
            id="kt_wizard_v1"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Body-->
            <div class="row justify-content-center my-10 px-8 my-lg-10 px-lg-8">
              <div class="col-xl-12">
                <!--begin: Wizard Form-->
                <Form
                  :form="form"
                  :title="'Tambah Surat Permohonan Uji Sampel Penelitian di Luar Prodi'"
                  route="api/sample-research-outside-forms"
                  purpose="add"
                />
                <!--end: Wizard Form-->
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/view/components/letters/sample-research-outside/Form.vue";
import { getUser } from "@/core/services/jwt.service.js";

export default {
  components: {
    Form,
  },
  data() {
    return {
      form: {
        student_id: getUser().id,
        student_name: getUser().name,
        registration_number: getUser().registration_number,
        address: getUser().address,
        phone: getUser().phone,
        email: getUser().email2,
        faculty_id: 1,
        letter_type_id: 1,
        study_program_id: getUser().major_id,
        year_id: getUser().year,
        teacher_mentor1:
          getUser().major_id == 1 || getUser().major_id == 3
            ? getUser().essay_mentor_1
            : getUser().thesis_mentor_1,
        teacher_mentor2:
          getUser().major_id == 1 || getUser().major_id == 3
            ? getUser().essay_mentor_2
            : getUser().thesis_mentor_2,
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Surat Permohonan Uji Sampel Penelitian di Luar Prodi",
        route: "/letters/sample-research-outside",
      },
      { title: "Tambah" },
    ]);
  },
};
</script>